import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const CustomizedHairWigforMen = () => {

  const seoData = {
    title: 'Customized Wigs For Men in Delhi - Radiance Hair Studio',
    description: 'Get the finest customized hair wigs for men in Delhi, custom-designed by certified hair experts to match your preferences. Visit us today.',
    keywords: ['Customized Wig For Men, Customized Wigs For Men in Delhi, Customized Wig For Men Price, Customized Wigs For Men Near Me']
  };


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Customized-Hair-Wig-for-Men'sv.jpg",
      "https://www.radiancehairstudio.com/customized-wigs-for-men2.png",
      "https://www.radiancehairstudio.com/customized-wigs-for-men1.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "category": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "description": "Get the finest customized hair wigs for men in Delhi, custom-designed by certified hair experts to match your preferences. Visit us today.",
    "disambiguatingDescription": [
      "Customized Wig For Men",
      "Customized Wigs For Men in Delhi",
      "Customized Wig For Men Price",
      "Customized Wigs For Men Near Me"
    ],
    "mainEntityOfPage": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "serviceType": "Customized Hair Wig for Men's"
  };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Customized-Hair-Wig-for-Men'sv.jpg",
      content: (
        <div>
          <h2>Customized Hair Wig for Men's</h2>
          <p>
          Are you in need to stop showing your bald head? Many people may advise you to
          pursue various therapies, but to be absolutely certain, you don't need any. Select a men's hair wig that has been specially made for you. You will maintain the concealment of your baldness while simultaneously developing a flawless appearance. 
          </p>
          <h2>Magic At Radiance</h2>
          <p>
          At Radiance, we specialize in providing high-quality, custom-made wigs for men, ensuring a perfect fit with superior craftsmanship. Our wigs are designed using only the finest materials—100% natural human hair with a freestyle design for a seamless and natural look.
          </p>
          <p>When choosing the ideal hair wig for men in Delhi, selecting the right hair part is essential. You can opt for baby hair, a front or back part, or a full perimeter design, depending on your preference. At Radiance, we tailor each wig to meet your specific needs, offering non-surgical hair replacement solutions crafted from premium human <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>hair extensions.</a></p>

          <p>Our <a href="/" style={{ color: "#373737"}}>Hair Studio</a> allows you to customize your wig according to your style, color, and comfort, all at the best prices. Our certified hair specialists are here to help you find the perfect wig that enhances your appearance. However, before making a decision, it’s essential to understand the benefits of wearing a wig compared to other hair restoration treatments. Here’s a closer look at why wigs are a great choice —</p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/customized-wigs-for-men1.png",
      largeImg: "/customized-wigs-for-men2.png",
      content: (
        <div>
          <h2>Advantages of Customized Hair Wigs for Men</h2>
         
          <ul>													
                <li>Customized hair wigs are specially designed to provide a perfect fit, ensuring a natural and seamless look. These wigs are tailored to match the shape of your scalp, enhancing your overall appearance without making it obvious that you're wearing one.</li>
                <li>One of the key highlights of these wigs is their realistic appeal. Made from high-quality natural hair, they blend effortlessly with your existing hair, making it nearly impossible for anyone to distinguish them from real hair.</li>
                <li>Comfort is another major benefit. These wigs are lightweight and breathable, allowing you to wear them throughout the day without discomfort. You can easily put them on like a cap when stepping out and remove them just as conveniently when indoors.</li>
                <li>In addition to their natural look and comfort, customized wigs are also cost-effective. Compared to other hair restoration methods, they are an affordable solution, making them a great choice for those looking to enhance their appearance without exceeding their budget. </li> 
                <li>
                If you're looking for a hassle-free and budget-friendly way to regain confidence in your hair, a customized wig is an excellent option!
                </li>
          </ul>
          
          
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of customized wigs",
      description:
        "If you’re considering getting a hair wig, you might have a few questions in mind. To clear up any confusion, we’ve answered some of the most common ones below.",
      description2: "We hope this answers your concerns! If you're looking for the best hair wig for men in Delhi, visit Radiance for a consultation and find the perfect solution for your hair needs.",
      faq: [
        {
          title: "How easy is it to wear a wig?",
          description:
            "At Radiance, we offer high-quality hair wigs for men in Delhi that are designed for convenience. You can wear them just like a cap—simply put them on or remove them whenever you like.",
        },
        {
          title: "Do I have to pay extra for longer hair?",
          description:
            "Our wigs typically range from 8 to 24 inches in length, which is ideal for most men. Since this length suits male hair requirements perfectly, there is no additional cost for longer wigs.",
        },
        {
          title: "Will one wig cover my entire head?",
          description:
            "At Radiance, we provide different types of hair wigs for men, including wigs for the front, back, baby hair, and full perimeter coverage. You can choose the best option based on your hair loss pattern.",
        },
        {
          title: "How natural will the wig look?",
          description:
            "Our wigs are crafted to provide an extremely natural appearance. They blend seamlessly with your existing hair, making it nearly impossible for anyone to distinguish between the wig and your real hair—even up close.",
        },
      ],
    },
    
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Customized Hair Wig for Men's" banner="/customized-hair-wig-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Customized Hair Wig for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default CustomizedHairWigforMen;
